
import { defineComponent } from 'vue';
import moment from 'moment';
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Advance Search Assesment Trainee',
  components: {
    Datatable,
    Field,
  },
  data() {
    return {
      moment: '' as any,
      tranches: [] as any,
      TranceData: [] as any,
      start_date: '',
      end_date: '',
      TranceLabel: [] as any,
      TpartnerLabel: [] as any,
      TinstituteLabel: [] as any,
      TCourseTypeLabel: [] as any,
      TCourseListLabel: [] as any,
      TCourseLabel: [] as any,
      TbatchesLabel: [] as any,
      selectedDistricts: [] as any,
      LicenseData: [],
      selectedTrance: [],

      licenseProvided: [
        {
          id: 1,
          name: 'Yes',
        },
        {
          id: 2,
          name: 'No',
        },
      ],
      updatedArray: [] as any,
      TpartnerData: [] as any,
      CourseTypeData: [] as any,
      CourseListData: [] as any,
      TinstituteData: [] as any,
      TbatchData: [] as any,
      genderData: [] as any,
      dropout: '' as any,
      absent: '' as any,
      searchType: '' as any,
      isDropOut: '' as any,
      isAbsent: '' as any,
      tableData: [] as any,
      api_url: '',
      tableHeader: [
        {
          name: 'Photo',
          key: 'photo',
        },
        {
          name: 'Entity',
          key: 'association',
          sortable: true,
          selected: true,
        },
        {
          name: 'Training Institute',
          key: 't_institute',
          sortable: true,
          selected: true,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: true,
          selected: true,
        },
        {
          name: 'Registration Number',
          key: 'reg_num',
          sortable: true,
          selected: true,
        },
        {
          name: 'Reference Number',
          key: 'ref_num',
          sortable: true,
          selected: true,
        },
        {
          name: 'Trainee Name',
          key: 'trainee_name',
          sortable: true,
          selected: true,
          width: '120px',
        },
        {
          name: 'Father',
          key: 'father',
          sortable: true,
        },
        {
          name: 'Mother',
          key: 'mother',
          sortable: true,
        },

        {
          name: 'NID',
          key: 'nid',
          sortable: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Date of Birth',
          key: 'dob',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Religion',
          key: 'religion',
          sortable: true,
        },

        {
          name: 'Assessor Info',
          key: 'as_info',
          sortable: true,
        },
        {
          name: 'Assessment Date',
          key: 'as_date',
          sortable: true,
        },
        {
          name: 'Is Reassessment',
          key: 'is_reas',
          sortable: true,
        },
        {
          name: 'Is Bill Submitted',
          key: 'is_bill_submit',
          sortable: true,
        },
        {
          name: 'Reassessment Date',
          key: 'reas_date',
          sortable: true,
        },
        {
          name: 'Assessment Title',
          key: 'as_title',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Total Marks',
          key: 'total_marks',
          sortable: true,
        },
        {
          name: 'Assessment Score',
          key: 'as_score',
          sortable: true,
        },
        {
          name: 'Remarks',
          key: 'remarks',
          sortable: true,
        },
        {
          name: 'Attendance Percentage',
          key: 'atd_percentage',
          sortable: true,
        },
        {
          name: 'Is Certification',
          key: 'is_certification',
          sortable: true,
        },
      ] as any,

      st: [] as any,
      pdfHeader: [] as any,
      instituteList: [] as any,
      courseType: [] as any,
      courseList: [] as any,
      batchList: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      entityInfos: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
      tableLoad: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranche();
    await this.associationList();
    await this.getCourseTypeList();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
           duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('course', this.CourseListData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('gender', this.genderData);
      formData.set('dropout', this.isDropOut);
      formData.set('absent', this.isAbsent);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/assesment_advance_excel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'assesment.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('course', this.CourseListData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('gender', this.genderData);
      formData.set('dropout', this.isDropOut);
      formData.set('absent', this.isAbsent);

      let data = `${this.VUE_APP_API_URL}/api/report/assesment_advance_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
           duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }

      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('course', this.CourseListData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('gender', this.genderData);
      formData.set('dropout', this.isDropOut);
      formData.set('absent', this.isAbsent);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/assesment_advance_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    isdropoutCheck(event) {
      if (event.target.checked) {
        this.isDropOut = -1;
      } else {
        this.isDropOut = '';
      }
    },
    isabsentCheck(event) {
      if (event.target.checked) {
        this.isAbsent = 0;
      } else {
        this.isAbsent = '';
      }
    },
    trancheLabelChange(data) {
      // this.getCourseListData();
      this.TranceLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.tranches.length; j++) {
          if (data[i] == this.tranches[j].id) {
            this.TranceLabel.push(this.tranches[j].label);
          }
        }
      }
    },
    trainingPartnerLabelChange(data) {
      // this.getCourseListData();
      this.getInstitute();
      this.TpartnerLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.entityInfos.length; j++) {
          if (data[i] == this.entityInfos[j].id) {
            this.TpartnerLabel.push(this.entityInfos[j].entity_short_name);
          }
        }
      }
    },
    trainingInstituteLabelChange(data) {
      this.getCourseListData();
      this.TinstituteLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.instituteList.length; j++) {
          if (data[i] == this.instituteList[j].id) {
            this.TinstituteLabel.push(this.instituteList[j].short_name);
          }
        }
      }
    },
    async getCourseTypeList() {
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseType = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseListData() {
      // this.load = true;
      await ApiService.get(
        'course/filterCourse?entity=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&courseType=' +
        this.CourseTypeData
      )
        .then((response) => {
          this.courseList = response.data.data;
          // this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseTypeLabelChange(data) {
      this.getCourseListData();
      this.TCourseTypeLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseType.length; j++) {
          if (data[i] == this.courseType[j].id) {
            this.TCourseTypeLabel.push(this.courseType[j].type_name);
          }
        }
      }
    },
    courseListLabelChange(data) {
      this.getBatches();
      this.TCourseListLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseList.length; j++) {
          if (data[i] == this.courseList[j].id) {
            this.TCourseListLabel.push(this.courseList[j].course_name);
          }
        }
      }
    },

    tbatchLabelChange(data) {
      this.TbatchesLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.batchList.length; j++) {
          if (data[i] == this.batchList[j].id) {
            this.TbatchesLabel.push(this.batchList[j].batch_sequence_number);
          }
        }
      }
    },
    resetData() {
      this.tableData = [] as any;
      this.st = [] as any;
      (this.TranceLabel = [] as any),
        (this.TpartnerLabel = [] as any),
        (this.TinstituteLabel = [] as any),
        (this.TCourseTypeLabel = [] as any),
        (this.TCourseListLabel = [] as any),
        (this.TCourseLabel = [] as any),
        (this.TbatchesLabel = [] as any),
        (this.TpartnerData = [] as any),
        (this.CourseTypeData = [] as any),
        (this.CourseListData = [] as any),
        (this.TinstituteData = [] as any),
        (this.TbatchData = [] as any),
        (this.genderData = [] as any),
        (this.TranceData = [] as any),
        (this.dropout = '' as any),
        (this.absent = '' as any),
        (this.isDropOut = '' as any),
        (this.isAbsent = '' as any),
        (this.searchType = '' as any),
        (this.showall = false);
      this.showTableData = false;
      this.componentTableKey += 1;
    },
    async generatetraineeAdvanceReport() {
      this.tableLoad = true;
      await ApiService.get(
        'assessment/advance_search?entity=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&course=' +
        this.CourseListData +
        '&institute=' +
        this.TinstituteData +
        '&batch=' +
        this.TbatchData +
        '&gender=' +
        this.genderData +
        '&dropout=' +
        this.isDropOut +
        '&absent=' +
        this.isAbsent
      )
        .then((response) => {
          console.log(response.data.data);
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;
          console.log(this.tableData);
          this.componentTableKey += 1;
          this.showTableData = true;
          this.tableLoad = false;
        })
        .then((response) => {
          console.log(response);
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstitute() {
      // this.load = true;
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/list?entity_id=' +
        this.TpartnerData +
        '&institute_info_id=' +
        institute_id
      )
        .then((response) => {
          console.log(response);
          this.instituteList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getBatches() {
      // this.load = true;
      await ApiService.get(
        'batch/multiple-batch-filter?tranche=' +
        this.TranceData +
        '&entity=' +
        this.TpartnerData +
        '&institute=' +
        this.TinstituteData +
        '&course=' +
        this.CourseListData
      )
        .then((response) => {
          console.log(response);
          this.batchList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getData() {
      if (this.TpartnerData.length || this.TranceData.length) {
        await ApiService.get(
          'report/motor-driving-batch?tranche=' +
          this.TranceData +
          '&entity=' +
          this.TpartnerData +
          '&institute=' +
          this.TinstituteData +
          '&batch=' +
          this.TbatchData +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;
            this.componentTableKey += 1;
          })
          .then((response) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Error Found',
          text: 'Phase or Training Partner Required',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    selectedTableHeader() {
      this.st = Array();

      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() { },
});
